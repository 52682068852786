import React from 'react'
import GridContainer from '@components/gridContainer'
import './headline.scss'

export const Headline = (props) => {
  const HeadlineTag = `h${props.priority}`;
  return (
    <>
      <HeadlineTag className={`headline${props.modifier ? ` ${props.modifier}` : ``}${props.priority ? ` headline--h${props.priority}` : ``}`}>{props.text}</HeadlineTag>
    </>
  )
}

Headline.defaultProps = {
  priority: 1
}

export default GridContainer(Headline)
