import React from "react"

export default (WrappedComponent) => {
const defaultCellSize = 12
class HOC extends React.Component {
  render() {
    return (
      <div className={`grid-wrapper${this.props.gridModifier ? ` grid-wrapper--${this.props.gridModifier}` : ``}`}>
        <div className="grid-container">
          <div className={`grid-cell${this.props.cellSize ? `--${this.props.cellSize}` : `--${defaultCellSize}`}`}>
            <WrappedComponent test={this.test} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
return HOC;
}
