import React from "react";
import { Link } from "react-router-dom";

import { getPageFromSitemap } from "@shared/app";
import GridContainer from "@components/gridContainer";
import { gridCellSize } from "@shared/utilities/helper";
import { RichText as RichTextWrapless } from "@components/richText";

import "./startpageCards.scss";

const Card = (props) => {
  const page = getPageFromSitemap(props.link);

  return (
    <div className="startpageCards-cardWrapper grid-cell--4">
      <Link to={page.url} className="startpageCards-card">
        <div className="startpageCards-imageWrapper">
          <img src={props.image} className="startpageCards-image" />
        </div>
        <div className="startpageCards-cardTitle">{props.title}</div>
      </Link>
    </div>
  );
};

const Cards = (props) => {
  const items = props.items;
  const allItems = items.map((card, index) => (
    <Card
      title={card.title}
      link={card.link}
      image={card.image.sizes.custom_600}
      key={index}
    />
  ));
  return <>{allItems}</>;
};

export const StartpageCards = (props) => {
  return (
    <>
      <div className="startpageCards">
        <div className="startpageCards-title">{props.headline}</div>
        {props.description &&
          <div className="startpageCards-description">
            <RichTextWrapless className={`paragraph${props.modifier ? ` ${props.modifier}` : ''}`} text={props.description}></RichTextWrapless>
          </div>
        }
        <div className="startpageCards-container grid-container">
          <Cards items={props.cards} />
        </div>
      </div>
    </>
  );
};

export default GridContainer(StartpageCards);
