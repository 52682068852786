import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import { getFrontpageFromSitemap, getPageFromSitemap } from "@shared/app";
import { setDocumentLanguage } from "@shared/utilities/language";
import { setCurrentPage, setLanguage, setLoading } from "@shared/app/store";

// TODO: move this out of shared
import Menu from "@components/menu";
import LoginPage from "@components/login-page";
import FrontPage from "@components/frontpage";

const renderFrontPage = () => {
  return (
    <>
      <Menu />
      <FrontPage />
    </>
  );
};

const Page = (props) => {
  const isAuthenticated = useSelector(
    (state) => state.preferences.authenticated
  );
  const currentLanguage = useSelector((state) => state.preferences.language);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (currentLanguage != params.lang) {
      setDocumentLanguage(params.lang);
      dispatch(setLanguage(params.lang));
    }
    if (props.fetch) {
      const page = props.frontpage
        ? params.lang === "jp"
          ? { id: 1026 }
          : { id: 36 }
        : getPageFromSitemap(
            `/${params.lang}/${params.slug}/`,
            "url",
            params.lang
          );

      dispatch(setLoading(true));
      if (page) {
        props.api
          .fetchPage(page, {
            lang: params.lang,
          })
          .then((response) => {
            dispatch(setCurrentPage(response));
            dispatch(setLoading(false));
          })
          .catch((err) => {
            console.error(`Unable to fetch page under ${params.slug}`);
          });
      } else {
        console.error(`Page not found`);
      }
    }
  }, [params.slug, params.lang]);

  return isAuthenticated ? (
    <div className="page">
      {props.frontpage ? renderFrontPage() : props.children}
    </div>
  ) : (
    <LoginPage />
  );
};

Page.defaultProps = {
  public: true,
};

export default Page;
