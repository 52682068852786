import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCookie } from "tiny-cookie";

import { t } from "@shared/app";
import { setAuthenticated } from "@shared/app/store";

import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";

import "./login.scss";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const language = useSelector((state) => state.preferences.language);

  const nameInput = useRef();
  const passwordInput = useRef();

  const handleSubmit = useCallback(() => {
    const name = nameInput.current.value;
    const password = passwordInput.current.value;

    if (name === "KurarayBranding" && password === "mk@6Wgb.!bJcZ-j8T2fx") {
      setError(false);
      setCookie("krr-bp", "token");
      dispatch(setAuthenticated(true));
    } else {
      setError("Incorrect Username or Password");
    }
  }, [nameInput, passwordInput]);

  return (
    <>
      <div className="login-logo">
        <LogoIcon />
      </div>
      <div className="login-dotBg" />
      <div className="login">
        <div className="login-poster" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="login-form"
        >
          <h1 className="headline headline--h1">
            Kuraray <br />
            brand portal
          </h1>
          <input ref={nameInput} type="text" placeholder="Username" />
          <input ref={passwordInput} type="password" placeholder="Password" />
          {error != false && (
            <p>
              <strong>{error}</strong>
            </p>
          )}
          {error === false && (
            <p>
              Register now for access: send a request to
              <a href="mailto:brandportal@kuraray.com">
                brandportal@kuraray.com
              </a>
            </p>
          )}
          <input type="submit" value={t("Login", language)} />
        </form>
      </div>
    </>
  );
};

export default LoginPage;
