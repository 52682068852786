import React from "react";
import GridContainer from "@components/gridContainer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { RichText as RichTextWrapless } from "@components/richText";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron.svg";

import "./slider.scss";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export const Slider = (props) => {
  const defaultModifier = "slider--caption-small slider--caption-left";
  return (
    <>
      <Splide
        className={`slider ${
          props.modifier ? ` ${props.modifier}` : `${defaultModifier}`
        }`}
        options={{
          arrows: true,
          width: "100%",
          gap: "20px",
          classes: {
            // Add classes for pagination.
            pagination: "splide__pagination slider-pagination", // container
            page: "splide__pagination__page slider-page", // each button
            arrows: "splide__arrows slider-arrows",
            arrow: "splide__arrow slider-arrow",
            prev: "splide__arrow--prev slider-arrow--prev",
            next: "splide__arrow--next slider-arrow--next",
          },
        }}
        renderControls={() => (
          <div className="splide__arrows slider-arrows">
            <button className="splide__arrow splide__arrow--prev slider-arrow slider-arrow--prev">
              <ChevronIcon className="slider-chevronIcon slider-chevronIcon--prev" />
            </button>
            <button className="splide__arrow splide__arrow--next slider-arrow slider-arrow--next">
              <ChevronIcon className="slider-chevronIcon slider-chevronIcon--next" />
            </button>
          </div>
        )}
      >
        {props.slides.map((slide, index) => (
          <SplideSlide key={index}>
            {slide.image.sizes && (
              <picture>
                <source
                  media="(min-width:900px)"
                  srcSet={slide.image.sizes.custom_2400}
                />
                <source
                  media="(min-width:400px)"
                  srcSet={slide.image.sizes.custom_1200}
                />
                <source
                  media="(min-width:300px)"
                  srcSet={slide.image.sizes.custom_600}
                />
                <img
                  className={`slider-pic${
                    slide.addborder ? " slider-pic--border" : ""
                  }`}
                  src={slide.image.sizes.custom_300}
                  alt={slide.alt}
                />
              </picture>
            )}
          </SplideSlide>
        ))}
      </Splide>
      {props.slides[0].caption && (
        <div className="slider-caption slider-caption--small">
          {/* {props.slides[0].caption} */}
          <RichTextWrapless className={`paragraph${props.modifier ? ` ${props.modifier}` : ''}`} text={props.slides[0].caption}></RichTextWrapless>
        </div>
      )}
    </>
  );
};

export default GridContainer(Slider);
