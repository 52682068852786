import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { StartpageHero } from "@components/startpageHero";
import StartpageCards from "@components/startpageCards";
import { StartpageTeaser } from "@components/startpageTeaser";
import { StartpageFooter } from "@components/startpageFooter";

const createPageContent = (tmp) => {
  if (tmp) {
    let hero = tmp.frontpage_hero;
    let categories = tmp.frontpage_categories;
    let teaser = tmp.frontpage_teaser;
    let templates = tmp.frontpage_templates;
    let footer = tmp.frontpage_footer;

    return (
      <>
        {hero && (
          <StartpageHero
            headline={hero.headline}
            image={hero.image}
            text={hero.text}
            button={{ id: hero.button, caption: hero.caption }}
          />
        )}
        {categories && (
          <div className="section">
            <StartpageCards
              headline={categories.headline}
              description={categories.description}
              cards={categories.cards}
            />
          </div>
        )}
        {teaser && (
          <div className="section section--backgroundColor">
            <StartpageTeaser
              headline={teaser.headline}
              description={teaser.description}
              card_title={teaser.card_title}
              card_link={teaser.card_link}
              image={teaser.image}
            />
          </div>
        )}
        {templates && (
          <div className="section">
            <StartpageTeaser
              headline={templates.headline}
              description={templates.description}
              card_title={templates.card_title}
              card_link={templates.card_link}
              image={templates.image}
            />
          </div>
        )}
        {footer && (
          <div className="section section--backgroundColorDark">
            <StartpageFooter
              headline={footer.headline}
              text={footer.text}
              contacts={footer.contacts}
            />
          </div>
        )}
      </>
    );
  }
  return <></>;
};

export default (props) => {
  const isLoading = useSelector((state) => state.application.loading);
  const page = useSelector((state) => state.page);
  const [content, setContent] = useState(<></>);

  useEffect(() => {
    setContent(createPageContent(page.tmp));
  }, [page.content]);

  return (
    <motion.div
      className="content content--frontpage"
      initial="hidden"
      animate={isLoading === true ? "hidden" : "visible"}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 0 },
      }}
    >
      {content}
    </motion.div>
  );
};
