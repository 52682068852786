import React from "react";

import BrandPlatform from "@shared/app";
import Content from "@components/content";
import Menu from "@components/menu";

import "./app.scss";

// TODO: WHAT IS THIS? (A: Shows component name in react devtools, instead of "Anonymous")
BrandPlatform.displayName = "Brand Platform";
Menu.displayName = "Menu";

export default (props) => {
  return (
    <BrandPlatform config={props.config}>
      <Menu />
      <Content />
    </BrandPlatform>
  );
};
