import React from 'react'
import GridContainer from '@components/gridContainer'
import { Video as VideoWrapless } from "@components/video";
import { gridCellSize } from "@shared/utilities/helper";

import './videoRow.scss'

export const VideoRow = (props) => {
  const numImages = props.videos.length

  // set grid cell width according to number of images in row
  const gridCellClass = gridCellSize(numImages)

  return (
    <>
      <div className={`videoRow${props.modifier ? ` ${props.modifier}` : ''}`}>
        <div className="grid-container">
          {props.videos.map((video, index) => {
            return (
              <div className={gridCellClass} key={index}>
                <VideoWrapless src={video.file.url} width={`${video.options.width ? `${video.options.width}` : '100'}`} poster={`${video.options.poster ? ` ${video.options.poster.sizes.large}` : ''}`} border={video.options.border}></VideoWrapless>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default GridContainer(VideoRow)