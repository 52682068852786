import React from "react";
import ReactDOM from "react-dom";

import { App } from "@components";
import { isProduction } from "@shared/utilities/env";

const render = (element) => {
  const config = element.dataset;
  ReactDOM.render(<App config={config} />, element);
};

console.warn(`Application Production: ${isProduction()}`);
render(document.querySelector(`.app`));
