import React, { Component } from 'react';
import GridContainer from '@components/gridContainer'
// import VideoPlayer from 'react-video-js-player';

import './video.scss'

export const Video = (props) => {
  return (
    <>
      <div className={`video${props.modifier ? ` ${props.modifier}` : ''}${props.border ? ` video--border` : ""}`}>
          <div className={`video-video${props.width ? ` video-video--${props.width}` : ''}`}>
            <video width="100%" height="auto" controls={false} poster={props.poster} autoPlay={true} muted={true} loop={true}>
              <source src={props.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
    </>
  )
}

// export class Video extends Component {
//   player = {}
//   state = {
//     video: {
//       src: this.props.src,
//       poster: (this.props.poster ? this.props.poster : null),
//     }
//   }

//   onPlayerReady(player) {
//     console.log("Player is ready: ", player);
//     this.player = player;
//   }

//   onVideoPlay(duration) {
//   }

//   onVideoPause(duration) {
//   }

//   onVideoTimeUpdate(duration) {
//   }

//   onVideoSeeking(duration) {
//   }

//   onVideoSeeked(from, to) {
//   }

//   onVideoEnd() {
//     player.play() // loop video (loop param not available)
//   }

//   render() {
//       return (
//         <div className={`video${this.props.modifier ? ` ${this.props.modifier}` : ''}${this.props.border ? ` video--border` : ""}`}>
//           <div className="video-video">
//             <VideoPlayer
//               autoplay={true}
//               controls={true}
//               src={this.state.video.src}
//               poster={this.state.video.poster}
//               // width="720"
//               // height="420"
//               onReady={this.onPlayerReady.bind(this)}
//               onPlay={this.onVideoPlay.bind(this)}
//               onPause={this.onVideoPause.bind(this)}
//               onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
//               onSeeking={this.onVideoSeeking.bind(this)}
//               onSeeked={this.onVideoSeeked.bind(this)}
//               onEnd={this.onVideoEnd.bind(this)}
//             />
//           </div>
//         </div>
//       );
//   }
// }

export default GridContainer(Video)
