import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "tiny-cookie";

import translations from "@resources/translations";

import Page from "@shared/app/page";
import {
  setSitemap,
  setTranslations,
  setAuthenticated,
} from "@shared/app/store";
import { api } from "@shared/api";

const DEFAULT_LANGUAGE = `en`;

export const url = (slug = "", language = DEFAULT_LANGUAGE) => {
  return `/${language}/${slug}`;
};

export default (props) => {
  const dispatch = useDispatch();
  const sitemap = useSelector((state) => state.sitemap);

  useEffect(() => {
    if (getCookie("krr-bp")) {
      dispatch(setAuthenticated(true));
    }
  }, []);

  useEffect(() => {
    dispatch(setTranslations(translations));
    api.fetchSitemap().then((response) => {
      dispatch(setSitemap(response));
    });
  }, []);

  const children = props.children;
  return Object.keys(sitemap).length > 0 ? (
    <Switch>
      <Route
        path="/:lang/:slug+"
        render={(props) => (
          <Page public={false} children={children} api={api} fetch="true" />
        )}
      />
      <Route
        path="/:lang"
        render={(props) => (
          <Page
            public={false}
            frontpage="true"
            children={children}
            api={api}
            fetch="true"
          />
        )}
      />
      <Route path="/*">
        <Redirect to={url()} />
      </Route>
    </Switch>
  ) : (
    <></>
  );
};
